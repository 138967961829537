<template>
  <div class="companyProjects">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active> Projects </CBreadcrumbItem>
    </teleport>

    <CCard>
      <CCardBody>
        <CRow class="w-100 mb-3 mx-0">
          <CCol class="GCFilterMaxWidth col-12">
            <GCFilter
              component="Projects"
              :except-column="['company_name']"
              @search="(val) => onTimeoutSearch(() => fetchData(val))"
            />
          </CCol>
        </CRow>
        <SmartTable
          :items="projects"
          :items-per-page="parseInt(perPage)"
          :columns="fields"
          :sorter-value="sort"
          :loading="loadingTable"
          :active-page="activePage"
          :per-page="perPage"
          :pages="pages"
          :clear-text="
            search.length ? 'No search results found' : 'No Data yet'
          "
          @sorter-change="onSort"
          @update-active-page="(value) => (activePage = value)"
          @update-per-page="(value) => (perPage = value)"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
