import CInputDateRange from '@/components/CInputDateRange'
import CSelectSearch from '@/components/CSelectSearch'
import { mapActions } from 'vuex'
import table from '@/mixin/table'
import GCFilter from '@/components/GCFilter/index'

export default {
  components: { CInputDateRange, CSelectSearch, GCFilter },
  mixins: [table],
  inject: ['mountedComponent'],
  data() {
    return {
      projects: [],
      fields: [
        {
          key: 'project_name',
          label: 'Project Name',
          _style: '',
          link: {
            name: 'Project',
            params: { id: 'id' },
            iconNewTab: true,
          },
        },
        { key: 'internal_number', label: 'Project Number', _style: '' },
        {
          key: 'owner_name',
          label: 'Owner Name',
          _style: '',
        },
        { key: 'stage', label: 'Stage', _style: '' },
        { key: 'estimated_start_date', label: 'Start Date', _style: '' },
        { key: 'estimated_end_date', label: 'End Date', _style: '' },
        {
          key: 'manager_full_name',
          label: 'Project Manager',
          _style: '',
          link: {
            name: 'CompanyUser',
            params: { user_id: 'manager_id', id: 'company_id' },
          },
        },
        { key: 'manager_phone', label: 'Manager Phone', _style: '' },
        {
          key: 'manager_email',
          label: 'Manager Email',
          _style: '',
          email: true,
        },
      ],
      projectFilter: [],
      tempFilter: {},
    }
  },
  watch: {
    projectFilter() {
      this.fetchData()
    },
  },
  methods: {
    ...mapActions(['fetchProjects']),
    openInNewTab(id) {
      let routeData = this.$router.resolve({
        name: 'ProjectDetails',
        params: { id: id },
      })
      window.open(routeData.href, '_blank')
    },
    makeQuery(f = {}) {
      f = Object.assign(this.tempFilter, f)
      return {
        params: {
          ...f,
          per_page: this.perPage,
          page: this.activePage,
          sort_field: this.sort && this.sort.column,
          sort_direction: this.sort && this.sort.state,
          company_id: this.$route.params.id,
        },
      }
    },
    fetchData(filter = {}) {
      this.loadingTable = true
      this.projects = []
      this.fetchProjects(this.makeQuery(filter))
        .then((res) => {
          this.key++
          this.projects = res.data
          this.pages = res.meta.last_page
          this.loadingTable = false
        })
        .catch(() => {
          this.loadingTable = false
        })
    },
  },
}
